<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="账户名称" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <span
                        style="
                            color: #2370eb;
                            cursor: pointer;
                            display: inline-block;
                        "
                        @click="onDetail(scope.row)"
                    >
                        {{ scope.row.account }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="产品名称"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.launchProductBO">{{
                        scope.row.launchProductBO.name
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="投放渠道"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    {{ $tableDataHandle.launchType(scope.row.channelType) }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="广告类型"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    {{ $tableDataHandle.launchAdvertType(scope.row.adType) }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="companyName"
                label="公司名称"
                width="193"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="地区"
                show-overflow-tooltip
                width="70"
            >
                <template slot-scope="scope">
                    {{
                        $address.address(scope.row.provinceId, scope.row.cityId)
                    }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="website"
                label="站点"
                width="145"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="telephone400"
                label="400电话"
                show-overflow-tooltip
                width="120"
            ></el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="余额" show-overflow-tooltip width="80">
                <template slot="header" slot-scope="scope">
                    余额
                    <i
                        class="el-icon-refresh-right"
                        @click="updateAmount({})"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">
                            更新时间:<br />{{ scope.row.updateTime }}
                        </div>
                        <span>
                            <i
                                :class="
                                    scope.row.refreshBalanceStatus == 1
                                        ? 'el-icon-success'
                                        : 'el-icon-warning'
                                "
                            ></i>
                            {{ scope.row.balanceAmount }}
                            <i
                                class="el-icon-refresh-right"
                                @click="updateAmount(scope.row)"
                            ></i>
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="投放状态"
                show-overflow-tooltip
                width="70"
            >
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.status"
                        active-color="#13ce66"
                        inactive-color="#C9C9C9"
                        :active-value="1"
                        :inactive-value="2"
                        @change="changeStatus(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.upDate"
                        @click="onUpDate(scope.row)"
                        style="color: #2370eb"
                        type="text"
                    >
                        编辑
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { launchList } from '@/api/user/launch/manage.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            tableRowId: '',
            searchDate: [],
            adminId: sessionStorage.getItem('adminId'),
            userType: sessionStorage.getItem('userType'),
            departmentId: sessionStorage.getItem('departmentId'),
        };
    },
    methods: {
        // 获取列表
        getData(productChoice, launchTypeChoice, pageNum, pageSize, btnP) {
            // // console.log(userType, searchVal, pageNum, pageSize);
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (productChoice) {
                data.param.productId = productChoice;
            }
            if (launchTypeChoice) {
                data.param.channelType = launchTypeChoice;
            }
            launchList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        onUpDate(data) {
            this.$emit('onUpDate', data);
        },
        onDetail(data) {
            this.$emit('onDetail', data);
        },
        updateAmount(data) {
            this.$emit('updateAmount', data);
        },
        changeStatus(data) {
            this.$emit('changeStatus', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
/deep/ th:nth-child(9) .cell {
    padding-left: 30px;
}
.el-icon-success {
    color: #2370eb;
}
.el-icon-warning {
    color: red;
}
.el-icon-refresh-right {
    color: #216ce5;
    cursor: pointer;
}
</style>
