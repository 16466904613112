<template>
  <div class="table">
    <el-table ref="multipleTable" :data="tableData" height="100%">
      <el-table-column label="账户名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ scope.row.launchBO.account }}
        </template>
      </el-table-column>
      <el-table-column label="时间" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ $handTime.formatDate(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="金额" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ scope.row.rechargeAmount }}元
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      tableData: [],
    };
  },
  components: {},
  created() {},
  methods: {
    getData(data) {
      this.tableData = data;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.table {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
}
/deep/ .el-table td,
/deep/ .el-table th {
  padding: 8px 0 !important;
}
/deep/ .el-table__body-wrapper {
  height: auto !important;
}
</style>
