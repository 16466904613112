<template>
  <div class="detail_form_box">
    <el-form label-position="left" size="small" label-width="85px">
      <el-form-item label="创建时间">
        <div class="info">{{ data.createTime || "- -" }}</div>
      </el-form-item>
      <el-form-item label="创建人">
        <div class="info">{{ data.adminName || "- -" }}</div>
      </el-form-item>
      <el-form-item label="账户">
        <div class="info">{{ data.account || "- -" }}</div>
      </el-form-item>
      <el-form-item label="产品名称">
        <div class="info" v-if="data.launchProductBO">
          {{ data.launchProductBO.name || "- -" }}
        </div>
      </el-form-item>
      <el-form-item label="渠道类型">
        <div class="info">
          {{ $tableDataHandle.launchType(data.channelType) }}
        </div>
      </el-form-item>
      <el-form-item label="广告类型">
        <div class="info">
          {{ $tableDataHandle.launchAdvertType(data.adType) }}
        </div>
      </el-form-item>
      <el-form-item label="公司名称">
        <div class="info">{{ data.companyName || "- -" }}</div>
      </el-form-item>
      <el-form-item label="地区">
        <div class="info">
          {{ $address.address(data.provinceId, data.cityId) }}
        </div>
      </el-form-item>
      <el-form-item label="服务商名称">
        <div class="info">{{ data.serviceName || "- -" }}</div>
      </el-form-item>
      <el-form-item
        label="联系人"
        v-for="item in data.launchContactBOList"
        :key="item.id"
      >
        <div class="info">
          <p>
            <span>{{ item.contactName || "- -" }}</span>
            {{ item.duty || "- -" }}
          </p>
          <p>
            <span>{{ $tableDataHandle.contactWay(item) }}</span>
            {{ item.contactContent || "- -" }}
          </p>
        </div>
      </el-form-item>
      <el-form-item label="付款账户">
        <div class="info">{{ data.paymentAccount || "- -" }}</div>
      </el-form-item>
      <el-form-item label="收款账户">
        <div class="info">{{ data.collectionAccount || "- -" }}</div>
      </el-form-item>
      <el-form-item label="站点">
        <div class="info">{{ data.website || "- -" }}</div>
      </el-form-item>
      <el-form-item label="400电话">
        <div class="info">{{ data.telephone400 || "- -" }}</div>
      </el-form-item>
    </el-form>
    <p class="form_title">API配置</p>
    <el-form ref="form" label-position="left" size="small" label-width="85px">
      <el-form-item label="账号">
        <div class="info">{{ data.userName || "- -" }}</div>
      </el-form-item>
      <el-form-item label="密码">
        <div class="info">{{ data.password || "- -" }}</div>
      </el-form-item>
      <el-form-item label="秘钥">
        <div class="info">{{ data.secretKey || "- -" }}</div>
      </el-form-item>
      <el-form-item label="360秘钥" v-if="data.channelType == 2">
        <div class="info">{{ data.apiSecret || "- -" }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      data: {},
    };
  },
  components: {},
  created() {},
  methods: {
    getData(row) {
      this.data = row;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.detail_form_box {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  .el-form {
    width: 90%;
    margin: 0 auto 20px;
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-form-item__label {
      font-weight: 600 !important;
    }
  }
  .info {
    span {
      display: inline-block;
      width: 50px;
    }
  }
}
.el-form-item {
  margin-bottom: 10px;
  width: 48%;
  margin-right: 2%;
  display: inline-block;
}
.form_title {
  padding-left: 25px;
  border-left: 3px solid #2370eb;
  margin-bottom: 15px;
  font-weight: 600;
}
</style>
