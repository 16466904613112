<template>
    <div class="add_launchDO_box">
        <div class="add_form">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="85px"
            >
                <el-form-item label="账户" required>
                    <el-input
                        placeholder="请输入内容"
                        :disabled="disabled"
                        v-model="launchDO.account"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="产品名称" required>
                    <el-select
                        style="width:270px"
                        v-model="launchDO.productId"
                        size="small"
                        :disabled="isEdit"
                        placeholder="请选择"
                        clearable
                        @change="addProduct"
                    >
                        <el-option
                            v-for="item in productList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                        <el-option label="+ 添加产品" value="add"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="渠道类型" required>
                    <el-select
                        :disabled="isEdit"
                        style="width:270px"
                        v-model="launchDO.channelType"
                        size="small"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in $searchForm.launchType()"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="广告类型" required>
                    <el-select
                        :disabled="isEdit"
                        style="width:270px"
                        v-model="launchDO.adType"
                        size="small"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in $searchForm.launchAdvertType()"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公司名称" required>
                    <el-input
                        placeholder="请输入内容"
                        :disabled="disabled"
                        v-model="launchDO.companyName"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="地区" required>
                    <el-cascader
                        placeholder="地区"
                        style="width:270px"
                        clearable
                        :disabled="disabled"
                        v-model="address"
                        :options="$address.cascader()"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="服务商名称" required>
                    <el-input
                        placeholder="请输入内容"
                        :disabled="disabled"
                        v-model="launchDO.serviceName"
                    >
                    </el-input>
                </el-form-item>

                <!-- <el-form-item label="开票信息" required>
          <el-input
            placeholder="请输入公司名称、税号、地址、电话 信息等"
            type="textarea"
            :disabled="disabled"
            autosize
            v-model="launchDO.billingInformation"
          >
          </el-input>
        </el-form-item> -->

                <el-form-item label="付款账户" required>
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        :disabled="disabled"
                        autosize
                        v-model="launchDO.paymentAccount"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="收款账户">
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        autosize
                        :disabled="disabled"
                        v-model="launchDO.collectionAccount"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="站点" required>
                    <el-input
                        placeholder="请输入内容"
                        :disabled="disabled"
                        v-model="launchDO.website"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="400电话" required>
                    <el-input
                        placeholder="请输入内容"
                        :disabled="disabled"
                        v-model="launchDO.telephone400"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    style="display:block;"
                    label="联系人"
                    required
                    v-for="(item, index) in launchContactDOList"
                    :key="index"
                >
                    <div style="margin-bottom:10px">
                        <p>
                            <el-input
                                placeholder="姓名"
                                style="width:88px"
                                :disabled="disabled"
                                v-model="item.contactName"
                            >
                            </el-input>
                            <el-input
                                placeholder="职务"
                                :disabled="disabled"
                                style="width:179px;margin-left:3px"
                                v-model="item.duty"
                            >
                            </el-input>
                            <el-button
                                v-if="index == 0 && !disabled"
                                @click="addContact"
                                type="primary"
                                class="el-icon-plus"
                                circle
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0 && !disabled"
                                @click.prevent="removeContact(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                size="mini"
                            ></el-button>
                        </p>
                        <p style="margin-top:12px">
                            <el-select
                                style="width:88px"
                                :disabled="disabled"
                                v-model="item.contactWay"
                                size="small"
                                placeholder="请选择"
                                clearable
                            >
                                <el-option label="电话" :value="1"></el-option>
                                <el-option label="微信" :value="2"></el-option>
                                <el-option label="邮箱" :value="3"></el-option>
                                <el-option label="QQ" :value="4"></el-option>
                                <el-option label="短信" :value="5"></el-option>
                                <el-option label="拜访" :value="6"></el-option>
                                <!--  <el-option label="官网表单" :value="8"></el-option> -->
                                <el-option label="座机" :value="9"></el-option>
                                <el-option label="其他" :value="7"></el-option>
                            </el-select>
                            <el-input
                                placeholder="号码"
                                :disabled="disabled"
                                style="width:179px;margin-left:3px"
                                v-model="item.contactContent"
                            >
                            </el-input>
                        </p>
                    </div>
                </el-form-item>
            </el-form>
            <p class="form_title">API配置</p>
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="85px"
            >
                <el-form-item label="账号" required>
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        :disabled="disabled"
                        autosize
                        v-model="launchDO.userName"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="密码" required>
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        :disabled="disabled"
                        autosize
                        v-model="launchDO.password"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="秘钥" required>
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        :disabled="disabled"
                        autosize
                        v-model="launchDO.secretKey"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="360秘钥"
                    required
                    v-if="launchDO.channelType == 2"
                >
                    <el-input
                        placeholder="请输入内容"
                        type="textarea"
                        :disabled="disabled"
                        autosize
                        v-model="launchDO.apiSecret"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="padding:7px 20px"
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="margin-right:15px;background:#2370EB;padding:7px 20px"
                @click="addlaunchDO"
                round
                size="mini"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { launchAdd, launchUpdate } from '@/api/user/launch/manage.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableId: '',
            launchDO: {},
            launchContactDOList: [
                {
                    contactWay: 1,
                },
            ],
            disabled: false,
            address: '',
            productList: [],
            isEdit: false,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row = {}, disabled) {
            // // console.log(row);
            //     先清空
            this.launchDO = {};
            (this.address = ''),
                (this.launchContactDOList = [
                    {
                        contactWay: 1,
                    },
                ]);
            this.disabled = disabled;
            if (!this.tableId) {
                return;
            }
            //  再赋值
            this.launchDO = {
                account: row.account,
                productId: row.launchProductBO.id,
                adType: row.adType,
                website: row.website,
                channelType: row.channelType,
                companyName: row.companyName,
                serviceName: row.serviceName,
                telephone400: row.telephone400,
                paymentAccount: row.paymentAccount,
                collectionAccount: row.collectionAccount,
                userName: row.userName,
                password: row.password,
                secretKey: row.secretKey,
                provinceId: row.provinceId,
                cityId: row.cityId,
                apiSecret: row.apiSecret,
            };
            this.address = [row.provinceId + '', row.cityId + ''];
            this.launchContactDOList = row.launchContactBOList;
        },
        // 保存信息
        addlaunchDO() {
            this.launchDO.provinceId = this.address[0];
            this.launchDO.cityId = this.address[1];
            if (!this.launchDO.account) {
                return this.$message.error('请输入账户名称');
            }
            if (!this.launchDO.productId) {
                return this.$message.error('请选择产品名称');
            }
            if (!this.launchDO.channelType) {
                return this.$message.error('请选择渠道类型');
            }
            if (!this.launchDO.adType) {
                return this.$message.error('请选择广告类型');
            }
            if (!this.launchDO.companyName) {
                return this.$message.error('请输入公司名称');
            }
            if (!this.launchDO.provinceId) {
                return this.$message.error('请选择地区');
            }
            if (!this.launchDO.serviceName) {
                return this.$message.error('请输入服务商名称');
            }
            if (!this.launchDO.paymentAccount) {
                return this.$message.error('请输入付款账户');
            }
            if (!this.launchDO.website) {
                return this.$message.error('请输入站点');
            }
            if (!this.launchDO.telephone400) {
                return this.$message.error('请输入400电话');
            }
            if (!this.launchDO.userName) {
                return this.$message.error('请输入API账户');
            }
            if (!this.launchDO.password) {
                return this.$message.error('请输入API密码');
            }
            if (!this.launchDO.secretKey) {
                return this.$message.error('请输入API秘钥');
            }
            if (this.launchDO.channelType == 2) {
                if (!this.launchDO.apiSecret) {
                    return this.$message.error('请输入360API秘钥');
                }
            }
            for (let i in this.launchContactDOList) {
                if (!this.launchContactDOList[i].contactName) {
                    return this.$message.error('请输入联系人');
                }
                if (!this.launchContactDOList[i].duty) {
                    return this.$message.error('请输入职务');
                }
                if (!this.launchContactDOList[i].contactWay) {
                    return this.$message.error('请选择联系方式');
                }
                if (!this.launchContactDOList[i].contactContent) {
                    return this.$message.error('请输入联系内容');
                }
            }
            let data = {
                param: {
                    launchDO: this.launchDO,
                    launchContactDOList: this.launchContactDOList,
                },
            };
            // // console.log(data);
            if (this.tableId) {
                data.param.launchDO.id = this.tableId;
                launchUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            } else {
                launchAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                        this.launchDO = {};
                        this.launchContactDOList = [
                            {
                                contactWay: 1,
                            },
                        ];
                        this.address = '';
                    }
                });
            }
        },
        //添加联系人
        addContact() {
            this.launchContactDOList.push({
                contactWay: 1,
            });
        },
        //     移出联系人
        removeContact(index) {
            this.launchContactDOList.splice(index, 1);
        },
        //  关闭
        onClose() {
            this.$emit('close');
        },
        addProduct(val) {
            if (val == 'add') {
                this.$emit('addProduct', true);
                this.launchDO.contractId = '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add_launchDO_box {
    width: 100%;
    height: 100%;

    .add_form {
        height: calc(100% - 49px);
        padding: 20px 0 0;
        box-sizing: border-box;
        overflow-y: auto;
        .el-form {
            width: 90%;
            margin: 0 auto 20px;
            display: flex;
            flex-wrap: wrap;
            /deep/ .el-form-item__label {
                font-weight: 600 !important;
            }
        }
        .info {
            display: flex;
            span {
                flex: 1;
            }
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
    .form_title {
        padding-left: 25px;
        border-left: 3px solid #2370eb;
        margin-bottom: 15px;
        font-weight: 500;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
    width: 270px;
}
.el-input {
    width: 270px;
}
.el-form-item {
    margin-bottom: 22px;
    width: 50%;
    display: inline-block;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
</style>
