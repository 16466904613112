import http from '@/utils/http.js'


// 投放产品添加
export function addLaunchProduct(data) {
     return http({
          url: "/jasoboss/launch/product/add",
          method: "post",
          data
     })
}
// 投放产品列表
export function launchProductList(data) {
     return http({
          url: "/jasoboss/launch/product/list",
          method: "post",
          data
     })
}
// 投放列表
export function launchList(data) {
     return http({
          url: "/jasoboss/launch/list",
          method: "post",
          data
     })
}
// 投放添加
export function launchAdd(data) {
     return http({
          url: "/jasoboss/launch/add",
          method: "post",
          data
     })
}
// 投放修改
export function launchUpdate(data) {
     return http({
          url: "/jasoboss/launch/update",
          method: "post",
          data
     })
}
// 投放余额刷新
export function launchAmountUpdate(data) {
     return http({
          url: "/jasoboss/launch/refresh/balance",
          method: "post",
          data
     })
}
// 启用数、总数
export function totalData(data) {
     return http({
          url: "/jasoboss/launch/brief/table/search",
          method: "post",
          data
     })
}
// 获取单个投放
export function oneLaunchData(data) {
     return http({
          url: "/jasoboss/launch/get",
          method: "post",
          data
     })
}
// 充值记录
export function rechargeList(data) {
     return http({
          url: "/jasoboss/launch/recharge/list/record",
          method: "post",
          data
     })
}