<template>
    <div class="manage_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span>产品名称</span>
                    <div class="radio_group">
                        <em
                            :class="active == -1 ? 'active' : ''"
                            @click="clickitem({}, -1)"
                            >全部

                            <i style="font-style: normal">
                                {{ allLaunchBriefProductRead + '/' }}
                                <i style="color: #999; font-style: normal">
                                    {{ allLaunchBriefProduct }}
                                </i>
                            </i>
                        </em>
                        <em
                            @click="clickitem(item, index)"
                            :class="active == index ? 'active' : ''"
                            v-for="(item, index) in productList"
                            :key="item.id"
                            >{{ item.name }}

                            <i
                                style="font-style: normal"
                                v-if="
                                    launchBriefProductList.length > 0 &&
                                    launchBriefProductList[index] &&
                                    item.id ==
                                        launchBriefProductList[index].productId
                                "
                            >
                                {{
                                    launchBriefProductList[index].openTotal +
                                    '/'
                                }}
                                <i style="color: #999; font-style: normal">
                                    {{ launchBriefProductList[index].total }}
                                </i>
                            </i>
                            <i v-else style="font-style: normal">
                                0/<i style="color: #999; font-style: normal"
                                    >0</i
                                >
                            </i>
                        </em>
                    </div>

                    <el-button
                        type="primary"
                        class="hoverBtn"
                        @click="addProduct"
                        >+ 产品</el-button
                    >
                </div>
            </div>
            <div class="search-two">
                <span>投放渠道</span>
                <div class="radio_group">
                    <em
                        :class="activeLaunchType == -1 ? 'active' : ''"
                        @click="clicklaunchType({}, -1)"
                        >全部

                        <i style="font-style: normal">
                            {{ allLaunchBriefChannelRead + '/' }}
                            <i style="color: #999; font-style: normal">
                                {{ allLaunchBriefChannel }}
                            </i>
                        </i>
                    </em>
                    <em
                        @click="clicklaunchType(item, index)"
                        :class="activeLaunchType == index ? 'active' : ''"
                        v-for="(item, index) in $searchForm.launchType()"
                        :key="item.value"
                        >{{ item.label }}

                        <i
                            style="font-style: normal"
                            v-if="
                                launchBriefChannelList.length > 0 &&
                                launchBriefChannelList[index] &&
                                item.value ==
                                    launchBriefChannelList[index].channelType
                            "
                        >
                            {{ launchBriefChannelList[index].openTotal + '/' }}
                            <i style="color: #999; font-style: normal">
                                {{ launchBriefChannelList[index].total }}
                            </i>
                        </i>
                        <i v-else style="font-style: normal">
                            0/<i style="color: #999; font-style: normal">0</i>
                        </i>
                    </em>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>
                投放管理列表
                <el-button
                    type="primary"
                    style="
                        background: #fff;
                        border-radius: 2px;
                        padding: 0 7px 0 12px;
                        height: 24px;
                        color: #333333;
                        border-color: #d9d9d9;
                        float: right;
                    "
                    @click="addAccount"
                    >+ 新增账户</el-button
                >
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @onUpDate="onUpDate"
                @onDetail="onDetail"
                @updateAmount="updateAmount"
                @changeStatus="changeStatus"
                @totalNum="totalNum"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增账户 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleClose"
            size="900px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增账户
                </div>
            </template>
            <AddAccount
                ref="addAccount"
                @addProduct="addProduct"
                @close="handleClose"
            ></AddAccount>
        </el-drawer>
        <!-- 编辑账户 -->
        <el-drawer
            :visible.sync="drawerUpdate"
            :direction="direction"
            :before-close="handleClose"
            size="900px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑账户
                </div>
            </template>
            <AddAccount ref="addAccount" @close="handleClose"></AddAccount>
        </el-drawer>
        <!-- 账户详情 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleClose"
            size="900px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    账户详情
                </div>
            </template>
            <Detail ref="detail" @close="handleClose"></Detail>
        </el-drawer>
        <!-- 添加产品 -->
        <!-- 添加客户-有客户 -->
        <el-dialog
            :visible.sync="dialogAddPro"
            width="560px"
            :before-close="handleDialogClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    增加产品
                </div>
            </template>
            <div class="dialog_add_customer_body">
                <el-form label-position="left" size="small">
                    <el-form-item label="产品名称" required>
                        <el-input
                            style="width: 270px"
                            placeholder="请输入内容"
                            v-model="productName"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleDialogClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitAddPro"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    addLaunchProduct,
    launchProductList,
    launchAmountUpdate,
    launchUpdate,
    totalData,
} from '@/api/user/launch/manage.js';
import Table from '../table/table.vue';
import AddAccount from '../addAccount/add.vue';
import Detail from '../detail/detail.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {},
            btnP: {},
            productList: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            drawerAdd: false,
            drawerUpdate: false,
            drawerDetail: false,
            dialogAddPro: false,
            tag: false,
            productName: '',
            active: -1,
            productChoiceId: '',
            activeLaunchType: -1,
            launchTypeChoiceId: '',
            launchBriefProductList: [],
            allLaunchBriefProduct: 0, //产品-总账户数
            allLaunchBriefProductRead: 0, //产品-总启用账户数
            launchBriefChannelList: [],
            allLaunchBriefChannel: 0, //渠道-总账户数
            allLaunchBriefChannelRead: 0, //渠道-总启用账户数
        };
    },
    components: {
        Table,
        AddAccount,
        Detail,
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            this.getData();
            this.launchProductList();
            this.totalData();
        },
        getData() {
            this.$refs.table.getData(
                this.productChoiceId,
                this.launchTypeChoiceId,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(total) {
            this.total = total;
        },
        totalData(item = {}) {
            this.allLaunchBriefProduct = 0;
            this.allLaunchBriefProductRead = 0;
            this.allLaunchBriefChannel = 0;
            this.allLaunchBriefChannelRead = 0;
            let data = {
                param: {},
            };
            if (item.id) {
                data.param.productId = item.id;
            }
            totalData(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.launchBriefProductList =
                        res.data.launchBriefProductList;
                    this.launchBriefChannelList =
                        res.data.launchBriefChannelList;
                    res.data.launchBriefProductList.forEach((item) => {
                        this.allLaunchBriefProduct += item.total;
                        this.allLaunchBriefProductRead += item.openTotal;
                    });
                    res.data.launchBriefChannelList.forEach((item) => {
                        this.allLaunchBriefChannel += item.total;
                        this.allLaunchBriefChannelRead += item.openTotal;
                    });
                }
            });
        },
        addAccount() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.addAccount.$data.tableId = '';
                this.$refs.addAccount.$data.disabled = false;
                this.$refs.addAccount.$data.productList = this.productList;
                this.$refs.addAccount.$data.isEdit = false;
            }, 0);
        },
        //     编辑
        onUpDate(row) {
            this.drawerUpdate = true;
            setTimeout(() => {
                this.$refs.addAccount.$data.tableId = row.id;
                this.$refs.addAccount.$data.productList = this.productList;
                this.$refs.addAccount.$data.isEdit = true;
                this.$refs.addAccount.getData(row, false);
            }, 0);
        },
        //     详情
        onDetail(row) {
            this.drawerDetail = true;
            setTimeout(() => {
                this.$refs.detail.getData(row);
            }, 0);
        },
        clickitem(item, index) {
            this.active = index;
            if (item.id) {
                this.productChoiceId = item.id;
            } else {
                this.productChoiceId = '';
            }
            this.activeLaunchType = -1;
            this.launchTypeChoiceId = '';
            this.currentPage = 1;
            this.pagesize = 50;
            this.getData();
            this.totalData(item);
        },
        clicklaunchType(item, index) {
            this.activeLaunchType = index;
            if (item.value) {
                this.launchTypeChoiceId = item.value;
            } else {
                this.launchTypeChoiceId = '';
            }
            this.getData();
        },
        //     添加 产品类型
        addProduct(tag = false) {
            this.dialogAddPro = true;
            this.tag = tag;
        },
        // 投放产品列表
        launchProductList() {
            let data = {
                param: {},
            };
            launchProductList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.productList = res.data.list;
                    if (this.drawerAdd) {
                        this.$refs.addAccount.$data.productList = res.data.list;
                    }
                }
            });
        },
        //     保存产品类型
        submitAddPro() {
            if (!this.productName) {
                return this.$message.error('请输入产品名称');
            }
            let data = {
                param: {
                    name: this.productName,
                },
            };
            addLaunchProduct(data).then((res) => {
                if (res.code == 200) {
                    this.dialogAddPro = false;
                    this.launchProductList();
                    this.productName = '';
                    if (this.tag) {
                        this.$refs.addAccount.$data.account.productId = '';
                    }
                }
            });
        },
        // 更新余额
        updateAmount(row = {}) {
            let data = {
                param: {},
            };
            if (row.id) {
                data.param.channelType = row.channelType;
                data.param.id = row.id;
                data.param.userName = row.userName;
                data.param.password = row.password;
                data.param.secretKey = row.secretKey;
                if (row.channelType == 2) {
                    data.param.apiSecret = row.apiSecret;
                }
            }
            launchAmountUpdate(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        // 修改投放状态
        changeStatus(row) {
            // // console.log(row.status);
            let data = {
                param: {
                    launchDO: {
                        id: row.id,
                        status: row.status,
                    },
                },
            };
            launchUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                    this.totalData();
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 重置
        emptyData() {
            this.searchVal = {};
        },
        handleClose() {
            this.drawerAdd = false;
            this.drawerUpdate = false;
            this.drawerDetail = false;
            this.getData();
        },
        handleDialogClose() {
            this.dialogAddPro = false;
            this.searchVal.name = '';
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.manage_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                // button {
                //   background: #2370EB;
                //   border-radius: 2px;
                //   border-color: #2370EB;
                //   margin-right: 16px;
                // }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .table {
        flex: 1;
    }
    .search-two {
        margin-bottom: 10px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-icon-sort-down {
        transform: rotateX(180deg) rotateZ(270deg);
        margin-left: 5px;
        color: #c0c4cc;
        vertical-align: middle;
    }
    .money_input {
        display: inline-block;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        /deep/ .el-input__inner {
            border: none;
            padding: 0 15px;
        }
    }
}
.dialog_add_customer {
    /deep/.el-dialog__footer {
        padding: 0;
        text-align: right;
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #eeeeee;
        padding-right: 18px;
    }
    .dialog_add_customer_body {
        width: 65%;
        margin: 60px auto 150px;
    }
}
.radio_group {
    display: inline-block;
    padding: 6px 0;
    em {
        padding: 7px 15px;
        font-size: 12px;
        font-style: normal;
        white-space: nowrap;
        background: #fff;
        border: 1px solid #dcdfe6;
        font-weight: 500;
        cursor: pointer;
        margin-left: -1px;
    }
    em:first-child {
        border-radius: 4px 0 0 4px;
        margin-left: 0;
    }
    .active {
        background: #fff !important;
        color: #2162cb !important;
        border-color: #2162cb !important;
    }
    .active + em {
        border-left: 1px solid #2162cb;
    }
}
.hoverBtn {
    padding: 8px 5px;
    margin-left: 15px;
    background: transparent;
    color: #2370eb;
    border-color: #2370eb;
}
.hoverBtn:hover {
    background-color: #2370eb;
    color: #fff;
}
</style>
