<template>
    <div class="detail_box">
        <div class="tab_bar">
            <span :class="tag ? 'active' : ''" @click="changeActive(true)"
                >基本信息</span
            >
            <span :class="tag ? '' : 'active'" @click="changeActive(false)"
                >充值记录</span
            >
        </div>
        <div class="main_box">
            <DetailForm ref="detailForm" v-show="tag"></DetailForm>
            <RechargeForm ref="rechargeForm" v-show="!tag"></RechargeForm>
        </div>
    </div>
</template>

<script>
import { oneLaunchData } from '@/api/user/launch/manage.js';
import DetailForm from './components/detailForm.vue';
import RechargeForm from './components/rechargeForm.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tag: true,
        };
    },
    components: {
        DetailForm,
        RechargeForm,
    },
    created() {},
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            oneLaunchData(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.detailForm.getData(res.data);
                    this.$refs.rechargeForm.getData(res.data);
                }
            });
        },
        changeActive(tag) {
            this.tag = tag;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.detail_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .tab_bar {
        height: 55px;
        box-sizing: border-box;
        border-bottom: 1px solid #e5e5e5;
        span {
            display: inline-block;
            height: 100%;
            line-height: 54px;
            color: #333333;
            font-size: 14px;
            margin-left: 33px;
            cursor: pointer;
            user-select: none;
        }
        span.active {
            color: #2370eb;
            position: relative;
        }
        .active:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 30%;
            height: 2px;
            background-color: #2370eb;
        }
    }
}
</style>
