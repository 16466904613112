import http from '@/utils/http.js'

// 充值申请
export function addRecharge(data) {
     return http({
          url: "/jasoboss/launch/recharge/add",
          method: "post",
          data
     })
}
// 充值记录
export function rechargeList(data) {
     return http({
          url: "/jasoboss/launch/recharge/list",
          method: "post",
          data
     })
}
// 获取单个充值记录
export function getOneRecharge(data) {
     return http({
          url: "/jasoboss/launch/recharge/get",
          method: "post",
          data
     })
}
// 充值审核
export function rechargeAudit(data) {
     return http({
          url: "/jasoboss/launch/recharge/audit/add",
          method: "post",
          data
     })
}
// 充值修改
export function updateRecharge(data) {
     return http({
          url: "/jasoboss/launch/recharge/update",
          method: "post",
          data
     })
}
// 表头上的数据统计
export function rechargeListProduct(data) {
     return http({
          url: "/jasoboss/launch/recharge/list/product",
          method: "post",
          data
     })
}
// 状态统计
export function statusBrief(data) {
     return http({
          url: "/jasoboss/launch/recharge/list/status/brief",
          method: "post",
          data
     })
}