<template>
    <div class="recharge_form_box">
        <div
            class="form_item"
            v-for="(item, index) in rechargeDataList"
            :key="index"
        >
            <div>
                <span>{{ item.monthDate }}</span>
                <p>共充值{{ item.rechargeTotalAmount }}元</p>
                <span style="text-align:right"
                    >{{ item.rechargeCount }}次
                    <em @click="changeStatus(index)"
                        >{{ item.tag ? '展开' : '收起' }}
                        <i
                            :class="
                                item.tag
                                    ? 'el-icon-arrow-down'
                                    : 'el-icon-arrow-up'
                            "
                        ></i> </em
                ></span>
            </div>
            <Table :ref="'table' + index" v-if="!item.tag"></Table>
        </div>
    </div>
</template>

<script>
import { rechargeList } from '@/api/user/launch/manage.js';
import { rechargeList as payRechargeList } from '@/api/user/launch/pay.js';
import Table from './table.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            rechargeDataList: [],
            tableRow: {},
        };
    },
    components: {
        Table,
    },
    created() {},
    methods: {
        getData(row) {
            this.tableRow = row;
            let data = {
                param: {
                    launchId: row.id,
                    status: 4,
                },
            };
            rechargeList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    res.data.list.forEach((item) => {
                        item.tag = true;
                    });
                    this.rechargeDataList = res.data.list;
                }
            });
        },
        changeStatus(index) {
            // console.log(index);
            this.rechargeDataList[index].tag = !this.rechargeDataList[index]
                .tag;
            if (!this.rechargeDataList[index].tag) {
                let data = {
                    param: {
                        launchId: this.tableRow.id,
                        monthDate: this.rechargeDataList[index].monthDate,
                        status: 4,
                    },
                    pageNum: 0,
                    pageSize: 0,
                };
                payRechargeList(data).then((res) => {
                    if (res.code == 200) {
                        this.$refs['table' + index][0].getData(res.data.list);
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.recharge_form_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 18px;
    .form_item {
        padding: 15px 20px;
        > div {
            display: flex;
            font-size: 13px;
            span {
                width: 150px;
                em {
                    font-style: normal;
                    color: #2162cb;
                    margin-left: 20px;
                    cursor: pointer;
                    user-select: none;
                }
            }
            p {
                flex: 1;
                text-align: center;
            }
        }
        background: #f3f5f7;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
    }
}
</style>
